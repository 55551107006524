<script setup lang="ts">
import moment from "moment";
import { computed, onUnmounted, ref } from "vue";

import { HOME_PSEUDO_PAGE } from "~/constants/page";
import { colorsByTheme } from "~/constants/style";
import { useAppStore, usePageStore, useUserStore } from "~/stores";
import { getMsUntilNext, getPartOfDay, getUserFormatDate } from "~/utils/time";
import MyTasksWidget from "~/views/widgets/MyTasksWidget.vue";
import RecentWidget from "~/views/widgets/RecentWidget.vue";
import TeammatesWidget from "~/views/widgets/TeammatesWidget.vue";
import UpcomingWidget from "~/views/widgets/UpcomingWidget.vue";

const WIDGETS = [
  { title: "My tasks", component: MyTasksWidget },
  { title: "Recent", component: RecentWidget },
  { title: "Upcoming", component: UpcomingWidget },
  { title: "Teammates", component: TeammatesWidget },
];

const appStore = useAppStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

appStore.setCurrentPage(HOME_PSEUDO_PAGE);

const now = ref(new Date());
let timeout: ReturnType<typeof setTimeout> | undefined;
const resetValuesAndTimeout = () => {
  now.value = new Date();
  clearTimeout(timeout);
  // eslint-disable-next-line no-restricted-syntax
  timeout = setTimeout(resetValuesAndTimeout, getMsUntilNext("hour"));
};
resetValuesAndTimeout();
const currentDate = computed(() => getUserFormatDate(moment(now.value)));
const greetingMessage = computed(() => `Good ${getPartOfDay(moment(now.value), true)}, ${userStore.firstName}`);

onUnmounted(() => {
  clearTimeout(timeout);
});
</script>

<template>
  <main class="flex justify-center overflow-y-scroll">
    <div class="flex max-w-[1408px] grow flex-col">
      <div class="mt-14 flex w-full select-none flex-col items-center gap-2 text-center text-hvy">
        <span class="font-medium">{{ currentDate }}</span>
        <span data-testid="greeting-message" class="text-3xl">{{ greetingMessage }}</span>
      </div>
      <div class="grid w-full grid-cols-1 gap-10 px-6 pt-14 extra-overscroll sm:grid-cols-2">
        <div v-for="widget in WIDGETS" :key="widget.title" class="flex flex-col gap-2">
          <span class="select-none text-lg font-medium text-md">{{ widget.title }}</span>
          <component :is="widget.component" :style="{ '--background': colors.bgLt, '--highlight': colors.bgStd }" />
        </div>
      </div>
    </div>
  </main>
</template>
