<script setup lang="ts">
import { computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import FormError from "~/components/dumb/FormError.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { ChevronDownIcon, XIcon } from "~/icons";
import { ButtonStyle, DropdownMenuItemKind, IconSize, Placement, UserRole } from "~/shared/enums";
import type { User } from "~/shared/types";
import { usePageStore, useTenantStore, useUserStore } from "~/stores";
import { makeUuid } from "~/utils/common";
import { isValidEmail } from "~/utils/validation";

const ROLES_MEMBER_CAN_ADD = new Set([UserRole.MEMBER, UserRole.GUEST]);

const props = defineProps<{
  users: User[];
}>();

const emit = defineEmits<{
  submit: [emails: string[], role: UserRole];
}>();

const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const emails = ref<string[]>([]);
const enteredValue = ref("");
const errorMsg = ref("");
const inputEl = ref<HTMLInputElement | null>(null);

const selectedRole = ref<UserRole>(tenantStore.isPremium ? UserRole.MEMBER : UserRole.ADMIN);

const hasPlaceholder = computed(() => emails.value.length === 0 && enteredValue.value === "");

const validate = () => {
  const trimmedValue = enteredValue.value.trim();
  if (!isValidEmail(trimmedValue)) {
    return { isValid: false, message: "This is not a valid email" };
  }

  // Prevent duplicate emails from being added
  if (emails.value.includes(trimmedValue)) {
    return { isValid: false, message: "This person is already on the list to add" };
  }

  // Prevent duplicate emails that are already teammates
  if (props.users.some((e) => e.email === trimmedValue)) {
    return { isValid: false, message: "This person is already a teammate" };
  }

  return { isValid: true, message: "" };
};

const submitButtonEnabled = computed(
  () => (emails.value.length > 0 && enteredValue.value === "") || validate().isValid
);

const label = computed(() => {
  const numberOfValidEmails = emails.value.length + (submitButtonEnabled.value && enteredValue.value !== "" ? 1 : 0);
  return `${selectedRole.value}${numberOfValidEmails > 1 ? "s" : ""}`;
});

const userIsAdmin = computed(() => userStore.isRoleGreaterOrEqual(UserRole.ADMIN));
const dropdownSections = computed(() => [
  {
    title: "Role",
    items: Object.values(UserRole).map((role) => ({
      title: role,
      hidden: !userIsAdmin.value && !ROLES_MEMBER_CAN_ADD.has(role),
      disabled: selectedRole.value === role,
      kind: DropdownMenuItemKind.BUTTON,
      dataTestid: `role-${role.toLowerCase().replace(/ /g, "-")}`,
      onClick: () => {
        selectedRole.value = role;
      },
    })),
  },
]);

const handleSubmit = () => {
  if (enteredValue.value !== "") {
    emails.value.push(enteredValue.value.trim().toLowerCase());
  }
  emit("submit", emails.value, selectedRole.value);
  emails.value = [];
  enteredValue.value = "";
  errorMsg.value = "";
};

const addEmail = () => {
  const cleanValue = enteredValue.value.trim().toLowerCase();
  const { isValid, message } = validate();

  if (!isValid) {
    errorMsg.value = message;
    return;
  }

  emails.value.push(cleanValue);
  enteredValue.value = "";
  errorMsg.value = "";
};

const removeEmail = (email: string) => {
  emails.value = emails.value.filter((e) => e !== email);
};

const onKeydown = (event: KeyboardEvent) => {
  // Add entered value to list if user hits enter, space or comma
  if (event.key === "Enter") {
    if (enteredValue.value === "") {
      handleSubmit();
    } else {
      addEmail();
    }
    return;
  }
  if (event.key === " ") {
    addEmail();
    event.preventDefault();
    return;
  }
  if (event.key === ",") {
    // If user hits comma, remove it from the input
    enteredValue.value = enteredValue.value.slice(0, -1);
    addEmail();
  }

  // remove the last chip if the user presses backspace or delete and the cursor is at the start of the input
  if ((event.key === "Backspace" || event.key === "Delete") && inputEl.value?.selectionStart === 0) {
    emails.value.pop();
  }
};

const id = ref(`input-${makeUuid()}`);
</script>

<template>
  <div class="flex items-center gap-2" @keydown="onKeydown" @click="inputEl?.focus()">
    <div
      class="relative flex w-full cursor-text items-center overflow-hidden rounded-md border p-1 bg-std text-hvy border-md focus-within:border-primary-base focus-within:outline focus-within:outline-2 focus-within:outline-indigo-400/30 hover:border-hvy focus-within:hover:border-primary-base focus:border-primary-base focus-within:dark:outline-indigo-400/10">
      <div
        v-for="chip in emails"
        :key="chip"
        :title="chip"
        class="my-0.5 ml-1 flex max-w-full items-center gap-1 truncate rounded px-1 text-base bg-md text-hvy">
        <span class="truncate">{{ chip }}</span>
        <Tooltip text="Remove email">
          <Button
            :btn-style="ButtonStyle.CHIP"
            :icon-size="IconSize.XS"
            :icon="XIcon"
            borderless
            is-contrast
            class="shrink-0 !p-0.5"
            @click="removeEmail(chip)" />
        </Tooltip>
      </div>

      <label :for="id" class="sr-only">Invite teammates by email</label>
      <input
        :id="id"
        ref="inputEl"
        v-model="enteredValue"
        v-auto-width="{ minWidthPx: 50, comfortZone: 5 }"
        data-testid="invite-teammate-input"
        type="text"
        class="my-0.5 max-w-full border-none border-transparent bg-transparent py-0 pl-2 text-sm text-hvy focus-ring-none placeholder:text-vlt focus-visible:border-transparent sm:text-base"
        :placeholder="hasPlaceholder ? (pageStore.isMobile ? 'Invite' : 'Invite teammates by email') : ''" />
      <DropdownMenu
        v-if="tenantStore.isPremium"
        :sections="dropdownSections"
        :placement="Placement.BOTTOM_RIGHT"
        :distance="0"
        :skidding="0"
        :width-pixels="140"
        class="flex flex-1 justify-end">
        <Tooltip text="Change user role">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            borderless
            :icon-after="ChevronDownIcon"
            :icon-size="IconSize.S"
            :text="label"
            height-block
            data-testid="select-teammate-role"
            a11y-label="Dropdown menu"
            class="pl-1.5 pr-1" />
        </Tooltip>
      </DropdownMenu>
    </div>
    <div class="flex items-center">
      <Button
        :btn-style="ButtonStyle.PRIMARY"
        text="Invite"
        data-testid="invite-teammate-button"
        class="!h-[38px] rounded"
        :disabled="!submitButtonEnabled"
        height-block
        a11y-label="Action"
        @click="handleSubmit" />
    </div>
  </div>
  <FormError :show="errorMsg.length > 1" :msg="errorMsg" />
</template>
