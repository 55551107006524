<script setup lang="ts">
import { useDocumentVisibility, useElementSize, useWindowFocus } from "@vueuse/core";
import type { Klass, LexicalEditor, LexicalNode, SerializedEditorState } from "lexical";
import {
  LexicalCollaborationPlugin,
  LexicalComposer,
  LexicalContentEditable,
  LexicalRichTextPlugin,
} from "lexical-vue";
import { computed, h, ref, render, watch } from "vue";
import { WebsocketProvider } from "y-websocket";
import * as Y from "yjs";

import { BACKUP_FONTS, colorsByTheme } from "~/constants/style";
import { useDataStore, useEnvironmentStore, usePageStore, useUserStore } from "~/stores";
import { isLexicalStateEmpty, someInHierarchy } from "~/utils/common";
import { when } from "~/utils/wait";

import CollaborationCursor from "./CollaborationCursor.vue";
import { LEXICAL_OUTSIDE_CLASS } from "./const";
import EditablePlugin from "./plugins/EditablePlugin.vue";
import EmojiMenuPlugin from "./plugins/EmojiMenuPlugin.vue";
import FocusPlugin from "./plugins/FocusPlugin.vue";
import HeadingNormalizationPlugin from "./plugins/HeadingNormalizationPlugin.vue";
import HorizontalRulePlugin from "./plugins/HorizontalRulePlugin.vue";
import ListNormalizationPlugin from "./plugins/ListNormalizationPlugin.vue";
import SetEditorStatePlugin from "./plugins/SetEditorStatePlugin.vue";
import TextContentPlugin from "./plugins/TextContentPlugin.vue";
import ToggleSectionPlugin from "./plugins/ToggleSectionPlugin.vue";
import EDITOR_THEME from "./theme";

const ONE_HOUR_IN_MS = 1000 * 60 * 60;

const props = defineProps<{
  namespace: string;
  nodes?: Klass<LexicalNode>[];
  initialState?: SerializedEditorState;
  placeholder: string;
  disabled?: boolean;
  singleLine?: boolean;
  grow?: boolean;
  minHeightPx?: number;
  maxHeightPx?: number;
  topMarginPx?: number;
  bottomMarginPx?: number;
  leftMarginPx?: number;
  rightMarginPx?: number;
  unrounded?: boolean;
  extraRounded?: boolean;
  normallyBorderless?: boolean;
  alwaysBorderless?: boolean;
  highContrastBorder?: boolean;
  baseText?: boolean;
  largeText?: boolean;
  smallLeading?: boolean;
  noWrap?: boolean;
  editorClasses?: string;
  collaboration?: {
    namespace: string;
    id: string;
  };
  dataTestid?: string;
}>();

const emit = defineEmits<{
  blur: [event: FocusEvent];
}>();

const dataStore = useDataStore();
const environmentStore = useEnvironmentStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const visibility = useDocumentVisibility();
const windowFocus = useWindowFocus();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const setEditorStatePlugin = ref<InstanceType<typeof SetEditorStatePlugin> | null>(null);
const focusManager = ref<InstanceType<typeof FocusPlugin> | null>(null);
const textContentPlugin = ref<InstanceType<typeof TextContentPlugin> | null>(null);
const hasFocus = ref(false);

const isEditable = computed(() => !props.disabled);
const initialStateEmpty = !props.initialState || isLexicalStateEmpty(props.initialState);

const initializeEditorStateInner = (editor: LexicalEditor) => {
  if (!props.initialState) {
    return;
  }

  const newState = editor.parseEditorState(props.initialState);
  editor.setEditorState(newState);
};
const initializeEditorState = (editor: LexicalEditor) => {
  if (!isEditable.value) {
    // eslint-disable-next-line no-restricted-syntax
    setTimeout(() => initializeEditorStateInner(editor));
    return;
  }

  initializeEditorStateInner(editor);
};

const editorConfig = {
  namespace: props.namespace,
  theme: EDITOR_THEME,
  nodes: props.nodes ?? [],
  editable: isEditable.value,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editorState: props.collaboration ? null : (initializeEditorState as any),
};

const topMarginPxNorm = computed(() => props.topMarginPx ?? 0);
const bottomMarginPxNorm = computed(() => `${props.bottomMarginPx ?? 0}px`);
const placeholderTopMarginPxNorm = computed(
  () =>
    `${topMarginPxNorm.value + (props.topMarginPx === 2 ? 0 : props.topMarginPx === -1 ? 3 : 2) + (props.largeText ? -2 : 2)}px`
);
const contentTopMarginPxNorm = computed(() => `${topMarginPxNorm.value - 2}px`);
const leftMarginPxNorm = computed(() => `${(props.leftMarginPx ?? 6) - 2}px`);

const editorRef = ref<HTMLElement | null>(null);

const synced = ref(!props.collaboration);
const syncKey = ref(0);

const rendered = ref(false);
const { height } = useElementSize(editorRef);
const { stop } = watch(height, () => {
  if (height.value === 0) {
    return;
  }
  rendered.value = true;
  stop();
});

const isReady = computed(() => synced.value && rendered.value);

const collaborationProvider = ref<WebsocketProvider | null>(null);
const lastSyncedDt = ref<Date | null>(null);

const collaborationProviderFactory = (id: string, yjsDocMap: Map<string, Y.Doc>) => {
  const documentId = `${props.collaboration?.namespace}-${id}`;
  const doc = new Y.Doc({
    guid: documentId,
  });
  yjsDocMap.set(id, doc);

  const provider = new WebsocketProvider(`${environmentStore.wsBase}/ws/v0/texts`, documentId, doc, {
    params: { client_duid: pageStore.duid },
  });

  collaborationProvider.value = provider;

  const onProviderSync = async (newSynced: boolean) => {
    synced.value = newSynced;
    if (newSynced) {
      lastSyncedDt.value = new Date();
    } else {
      syncKey.value += 1;
    }
  };
  provider.on("sync", onProviderSync);
  return provider;
};

const onUnidle = () => {
  if (!lastSyncedDt.value || new Date().getTime() - lastSyncedDt.value.getTime() < ONE_HOUR_IN_MS) {
    return;
  }
  collaborationProvider.value?.disconnect();
};

watch(
  () => visibility.value,
  (newVisibility) => {
    if (newVisibility === "hidden") {
      return;
    }
    onUnidle();
  }
);
watch(
  () => windowFocus.value,
  (newFocus) => {
    if (!newFocus) {
      return;
    }
    onUnidle();
  }
);

const onError = (error: Error) => {
  throw error;
};

const focusInner = (start?: boolean) => focusManager.value?.focus(start);

const focus = (start?: boolean) => {
  if (synced.value) {
    focusInner(start);
    return;
  }
  when(synced, () => focusInner(start));
};

const onBlur = (event: FocusEvent) => {
  if (
    someInHierarchy(
      event.relatedTarget as HTMLElement | null,
      (e) => e === editorRef.value || e.classList.contains(LEXICAL_OUTSIDE_CLASS)
    )
  ) {
    return;
  }

  hasFocus.value = false;
  emit("blur", event);
};

const setEditorState = (state: SerializedEditorState) => setEditorStatePlugin.value?.setEditorState(state);

const getTextContent = () => textContentPlugin.value?.getTextContent();

const createCursor = (cursor: { name: string; color: string }, caret: HTMLSpanElement) => {
  const user = dataStore.getUserByDuid(cursor.name);
  const node = h(CollaborationCursor, { user });
  render(node, caret);
};

defineExpose({
  focus,
  hasFocus,
  isReady,
  getTextContent,
  setEditorState,
  collaborationProvider,
});
</script>

<template>
  <TextEditor v-if="!synced" v-bind="{ ...props, disabled: true, collaboration: undefined }" />
  <LexicalComposer :key="syncKey" :initial-config="editorConfig as any" @error="onError">
    <div
      class="group/text-editor relative"
      :data-testid="dataTestid"
      :class="[
        editorClasses,
        {
          border: !alwaysBorderless,
          'hover:border-md': !alwaysBorderless && !highContrastBorder && !hasFocus,
          'hover:border-hvy': !alwaysBorderless && highContrastBorder && !hasFocus,
          'border-transparent': !alwaysBorderless && normallyBorderless && !hasFocus,
          'border-lt': !alwaysBorderless && !normallyBorderless && !highContrastBorder && !hasFocus,
          'border-md': !alwaysBorderless && highContrastBorder !== hasFocus,
          'border-primary-base': !alwaysBorderless && highContrastBorder && hasFocus,
          rounded: !unrounded,
          'rounded-lg': extraRounded,
          'w-full': !grow,
          'w-auto min-w-[40px]': grow,
          hidden: !synced,
        },
      ]">
      <slot name="before" />
      <div
        ref="editorRef"
        class="dart-editor relative"
        :class="collaboration ? 'dart-cursors-container' : undefined"
        :style="{ '--textColor': colors.textMd }">
        <EditablePlugin :editable="isEditable" />
        <FocusPlugin v-if="isEditable" ref="focusManager" :has-focus="hasFocus" />
        <LexicalRichTextPlugin>
          <template v-if="placeholder && (isEditable || initialStateEmpty)" #placeholder>
            <div
              class="pointer-events-none absolute top-0 select-none pl-0.5"
              :class="{
                'text-2xl font-semibold text-gray-300 dark:text-zinc-600': largeText,
                'font-normal': !largeText,
                'text-gray-900/30 dark:text-white/20': !largeText,
                'text-sm/6': !baseText && !largeText && !smallLeading,
                'text-base': baseText && !largeText && !smallLeading,
                'text-sm/5': !largeText && smallLeading,
                '!whitespace-nowrap': noWrap && !singleLine,
              }"
              :style="{ marginTop: placeholderTopMarginPxNorm, marginLeft: leftMarginPxNorm }">
              {{ placeholder }}
            </div>
          </template>
          <template #contentEditable>
            <LexicalContentEditable
              auto-capitalize
              auto-complete
              auto-correct
              spellcheck
              enable-grammarly
              :editable="isEditable"
              data-testid="text-editor"
              :class="{
                rounded: !unrounded,
                'overflow-hidden !whitespace-pre': singleLine,
                'text-2xl font-semibold': largeText,
                'font-normal': !largeText,
                'text-sm/6': !baseText && !largeText && !smallLeading,
                'text-base': baseText && !largeText && !smallLeading,
                'text-sm/5': !largeText && smallLeading,
                '!whitespace-nowrap': noWrap && !singleLine,
                'overflow-y-auto': maxHeightPx !== undefined,
              }"
              class="relative pl-0.5 pr-1.5 outline-none text-md focus-ring-none"
              :style="{
                minHeight: minHeightPx !== undefined ? `${minHeightPx}px` : undefined,
                maxHeight: maxHeightPx !== undefined ? `${maxHeightPx}px` : undefined,
                marginTop: contentTopMarginPxNorm,
                marginLeft: leftMarginPxNorm,
                marginRight: rightMarginPx !== undefined ? `${rightMarginPx}px` : undefined,
                paddingBottom: bottomMarginPxNorm,
              }"
              v-bind="{ ariaLabel: placeholder }"
              @focus="hasFocus = true"
              @blur="onBlur"
              @keydown.esc.stop.prevent />
          </template>
        </LexicalRichTextPlugin>
        <slot />
        <LexicalCollaborationPlugin
          v-if="collaboration"
          :id="collaboration.id"
          :provider-factory="collaborationProviderFactory as any"
          :initial-editor-state="initializeEditorState"
          :should-bootstrap="false"
          :cursors-container-ref="editorRef"
          :username="userStore.duid"
          :cursor-color="userStore.colorHex"
          :custom-cursor-fn="createCursor" />
        <SetEditorStatePlugin ref="setEditorStatePlugin" />
        <!-- Custom plugins -->
        <EmojiMenuPlugin v-if="isEditable" />
        <HeadingNormalizationPlugin />
        <HorizontalRulePlugin />
        <ListNormalizationPlugin />
        <TextContentPlugin ref="textContentPlugin" />
        <ToggleSectionPlugin />
      </div>
      <slot name="after" />
    </div>
  </LexicalComposer>
</template>

<style>
.dart-cursors-container > div:last-child > span > span:last-child > span:first-child {
  border-radius: 2px;
  top: unset !important;
  bottom: -16px !important;
  font-family: "Inter var", v-bind("BACKUP_FONTS") !important;
  color: var(--textColor) !important;
  padding: 2px 4px !important;
}
</style>
