<script setup lang="ts">
import { computed, getCurrentInstance, nextTick, onMounted, onUnmounted, ref } from "vue";
import type { ComponentExposed } from "vue-component-type-helpers";

import Button from "~/components/dumb/Button.vue";
import DragArea from "~/components/dumb/DragArea.vue";
import PageEmptyState from "~/components/dumb/PageEmptyState.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import PageCard from "~/components/PageCard.vue";
import { VIEWS_PSEUDO_PAGE } from "~/constants/page";
import { PlusIcon } from "~/icons";
import { ButtonStyle, CommandId, ViewKind } from "~/shared/enums";
import type { View } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";

const currentInstance = getCurrentInstance();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

appStore.setCurrentPage(VIEWS_PSEUDO_PAGE);

const dragArea = ref<ComponentExposed<typeof DragArea<View, typeof PageCard>> | null>(null);
const views = computed(() => dataStore.viewList.filter((view) => view.kind === ViewKind.CUSTOM));

const createViewAndStartEditing = () => {
  const order = getOrdersBetween(undefined, views.value[0]?.order)[0];
  dataStore.createView(order);

  nextTick(() => {
    const itemRefs = dragArea.value?.itemRefs ?? [];
    if (itemRefs.length === 0) {
      return;
    }

    itemRefs[itemRefs.length - 1].startEditingTitle();
  });
};

const getComponentProps = (view: View) => ({
  page: view,
});

const moveView = (_: string, view: View) => {
  dataStore.updateView({
    duid: view.duid,
    order: view.order,
  });
};

onMounted(() => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appStore.viewsRoot = (currentInstance?.exposeProxy ?? currentInstance?.exposed ?? null) as any;
});

onUnmounted(() => {
  appStore.viewsRoot = null;
});

defineExpose({
  createView: createViewAndStartEditing,
});
</script>

<template>
  <div class="relative flex size-full">
    <div class="flex size-full justify-center overflow-y-scroll">
      <div
        v-if="views.length > 0"
        class="flex h-fit w-full max-w-xl grow flex-col px-6 extra-overscroll"
        :class="pageStore.isMobile ? 'mt-6' : 'mt-28'">
        <div class="flex items-end justify-between border-b px-2 py-3 border-md">
          <div class="select-none text-2xl font-semibold text-hvy">Views</div>
          <Tooltip :command-id="CommandId.CREATE_VIEW">
            <Button
              :btn-style="ButtonStyle.SECONDARY"
              :icon="PlusIcon"
              borderless
              data-testid="create-new-view"
              a11y-label="Create a view"
              class="!p-0.5"
              @click="createViewAndStartEditing" />
          </Tooltip>
        </div>
        <DragArea
          ref="dragArea"
          group="view-editor"
          category="views"
          class="!h-auto w-full divide-y divide-lt"
          :items="views"
          :component="PageCard"
          :get-component-props="getComponentProps"
          @change="moveView" />
      </div>
      <PageEmptyState v-else />
    </div>
  </div>
</template>
S
