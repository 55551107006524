<script setup lang="ts">
import { computed, nextTick, onUnmounted, ref, watch } from "vue";

import actions from "~/actions";
import { UNGROUPED_PSEUDO_GROUP_BY } from "~/common/groupBy";
import { getPropertyPartialTask, getPropertyValueFromTask } from "~/common/properties";
import Tooltip from "~/components/dumb/Tooltip.vue";
import TaskChips from "~/components/visualization/components/TaskChips.vue";
import { THROTTLE_MS } from "~/constants/app";
import { ChevronLeftDoubleIcon, FullscreenIcon } from "~/icons";
import {
  CommandId,
  DartboardKind,
  EditorMode,
  PageKind,
  RelationshipKindKind,
  SubtaskDisplayMode,
  TaskDetailMode,
} from "~/shared/enums";
import type { Task } from "~/shared/types";
import { useAppStore, useDataStore, usePageStore, useUserStore } from "~/stores";
import { makeUuid, someInHierarchy } from "~/utils/common";
import { ThrottleManager } from "~/utils/throttleManager";

const props = defineProps<{
  task: Task;
  value: string;
  editorMode: EditorMode;
  hasShownParent: boolean;
  subtasksExpanded: boolean;
}>();

const emit = defineEmits<{
  toggleSubtasks: [];
}>();

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const userStore = useUserStore();

const isListMiniTcmMode = computed(() => props.editorMode === EditorMode.LIST_MINI_TCM);
const isListMiniRoadmapMode = computed(() => props.editorMode === EditorMode.LIST_MINI_ROADMAP);
const isListMiniNonRoadmapMode = computed(() => props.editorMode === EditorMode.LIST_MINI || isListMiniTcmMode.value);

const canAddTasks = computed(
  () => appStore.currentPage?.pageKind !== PageKind.VIEW && appStore.currentPage?.kind !== DartboardKind.FINISHED
);

const title = ref(props.value);

const inputRef = ref<HTMLInputElement | null>(null);
const id = ref(`input-${makeUuid()}`);

const globalTask = computed(() => dataStore.getTaskByDuid(props.task.duid));
const neverUpdated = props.task.createdAt === props.task.updatedAt;

const notFlat = computed(() => appStore.subtaskDisplayMode !== SubtaskDisplayMode.FLAT);
const isSelected = computed(() => appStore.selectedTaskDuids.has(props.task.duid));
const openTaskDisabled = computed(
  () => isSelected.value && (appStore.taskDetailOpen || appStore.selectedTaskDuids.size > 1)
);

const focus = () => {
  inputRef.value?.focus();
};

const openTaskInDetail = (subtaskOverride: boolean) => {
  const visualization = appStore.getBaseVisualization();

  if (
    visualization.getSelectedRows().length > 1 ||
    appStore.taskOpenInDetail === props.task ||
    (isListMiniNonRoadmapMode.value && !subtaskOverride)
  ) {
    return;
  }

  const { duid } = props.task;
  if (!visualization.getRow(duid)) {
    actions.visualization.navigateToTask(duid);
    return;
  }

  if (isListMiniNonRoadmapMode.value) {
    visualization.selectAndScrollTo(duid);
    return;
  }

  // eslint-disable-next-line no-restricted-syntax
  setTimeout(() => {
    appStore.setTaskDetailOpen(true);
  });
};

const clickReminderOpen = () => {
  appStore.setTasksOpenInReminderModal([props.task]);
};

const jumpToDescription = () => {
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  actions.visualization.jumpToDescription(props.task);
};

const jumpToComments = () => {
  actions.visualization.selectRowByIdAndScroll(props.task.duid);
  actions.visualization.jumpToComments(props.task);
};

const toggleSubtasks = () => {
  if (!notFlat.value) {
    openTaskInDetail(false);
    return;
  }
  emit("toggleSubtasks");
};

let isTabbing = false;

const deleteTaskIfEmpty = (event?: FocusEvent) => {
  if (
    event &&
    someInHierarchy(event.relatedTarget as HTMLElement | null, (e) => e.getAttribute("row-id") === props.task.duid)
  ) {
    return false;
  }
  if (isTabbing) {
    isTabbing = false;
    return false;
  }
  if (!dataStore.getTaskByDuid(props.task.duid)) {
    // task was already deleted by backspace
    return true;
  }
  if (!neverUpdated || title.value.trim()) {
    return false;
  }

  dataStore.deleteTasks([props.task]);
  // TODO scroll back to select (and maybe start editing) the next higher task or the new task input
  return true;
};

const saveManager = new ThrottleManager((value: string) => {
  dataStore.updateTasks(
    [
      {
        duid: props.task.duid,
        title: value,
      },
    ],
    { noUndo: true, onRemapFailed: () => dataStore.deleteTasks([props.task]) }
  );
}, THROTTLE_MS);

const onInput = (event: Event) => {
  const inputElement = event.target as HTMLInputElement;
  if (!inputElement) {
    return;
  }
  const selectionStart = inputElement.selectionStart ?? 0;
  const selectionEnd = inputElement.selectionEnd ?? 0;

  const newTitle = inputElement.value.trim();
  title.value = newTitle;
  saveManager.run(newTitle);

  if (!globalTask.value) {
    return;
  }
  // TODO consider getting rid of globalTask and using dataStore.updateTasks(..., { noBackend: true }) instead
  globalTask.value.title = newTitle;

  nextTick(() => {
    if (inputRef.value) {
      inputRef.value.setSelectionRange(selectionStart, selectionEnd);
    }
  });
};

const onPaste = async (event: ClipboardEvent) => {
  event.preventDefault();

  const text = await navigator.clipboard.readText();

  if (!inputRef.value) {
    return;
  }

  const selectionStart = inputRef.value.selectionStart || 0;
  const selectionEnd = inputRef.value.selectionEnd || 0;

  const visualization = appStore.getActiveVisualization();
  const allRows = visualization.getAll();
  const indexOfTask = allRows.findIndex((row: Task) => row.duid === props.task.duid);
  const belowTaskOrder = allRows[indexOfTask + 1]?.order ?? "";

  const parentKindDuid = dataStore.getRelationshipKindByKind(RelationshipKindKind.PARENT_OF).duid;
  const parentTaskDuid = dataStore
    .getRelationships(props.task)
    .find((e) => e.kindDuid === parentKindDuid && !e.isForward)?.targetDuid;

  const remainingText = actions.task.createTasksFromText(
    text,
    props.task.dartboardDuid,
    props.task.order,
    belowTaskOrder,
    {
      drafterDuid: isListMiniTcmMode.value ? userStore.duid : undefined,
    },
    {
      parentTaskDuid,
    }
  );

  const currentText = inputRef.value.value;

  const updatedText =
    currentText.substring(0, selectionStart) + (remainingText ?? "") + currentText.substring(selectionEnd);

  inputRef.value.value = updatedText;
  title.value = updatedText;
  saveManager.run(updatedText);

  const newCursorPosition = selectionStart + (remainingText?.length ?? 0);
  nextTick(() => {
    inputRef.value?.focus();
    inputRef.value?.setSelectionRange(newCursorPosition, newCursorPosition);
  });
};

const onBlur = (event: FocusEvent) => {
  if (deleteTaskIfEmpty(event)) {
    return;
  }

  saveManager.finish();
};

const onKeydown = (event: KeyboardEvent) => {
  if (inputRef.value === null) {
    return;
  }

  if (event.key === "Escape") {
    event.stopPropagation();
    event.preventDefault();
    inputRef.value.blur();
    return;
  }

  if (event.key === "Enter") {
    inputRef.value.blur();
    if (canAddTasks.value && title.value.trim()) {
      const partialTask: Partial<Task> = {};

      if (isListMiniRoadmapMode.value) {
        partialTask.startAt = props.task.startAt;
        partialTask.dueAt = props.task.dueAt;
      }

      if (appStore.groupBy !== UNGROUPED_PSEUDO_GROUP_BY) {
        Object.assign(
          partialTask,
          getPropertyPartialTask(
            appStore.groupByDefinition.property,
            partialTask,
            getPropertyValueFromTask(appStore.groupByDefinition.property, props.task)
          )
        );
      }

      actions.visualization.createTaskUnderneath(partialTask);
    }
    return;
  }

  if (event.key === "Tab") {
    event.preventDefault();
    isTabbing = true;
    inputRef.value.blur();
    if (!isListMiniNonRoadmapMode.value) {
      const partialTask = { title: title.value.trim() };
      if (event.shiftKey) {
        actions.visualization.subtaskOutdent(partialTask);
      } else {
        actions.visualization.subtaskIndent(partialTask);
      }

      actions.visualization.openKeyboardIfIos();
      // eslint-disable-next-line no-restricted-syntax
      setTimeout(() => {
        actions.visualization.startEditingTitle();
      }, 100);
    }
    return;
  }

  if (event.key === "Backspace") {
    deleteTaskIfEmpty();
  }
};

watch(
  () => props.value,
  (newValue) => {
    if (newValue.trim() === title.value.trim()) {
      return;
    }
    title.value = newValue;
  }
);

onUnmounted(() => {
  saveManager.destroy();
});

defineExpose({
  focus,
});
</script>

<template>
  <div class="group/title flex h-full min-h-9 grow items-center gap-1.5 truncate">
    <div
      class="flex truncate rounded border border-transparent"
      :class="!pageStore.isPublicView && !task.inTrash && 'group-hover/title:border-oncolor'">
      <label :for="id" class="sr-only">{{ title }}</label>
      <input
        :id="id"
        ref="inputRef"
        v-auto-width="{ minWidthPx: 40, extraWidth: 1 }"
        type="text"
        :value="title"
        :title="title"
        data-testid="title-editor"
        class="min-h-5 w-full cursor-text truncate border-none bg-transparent px-px py-0 text-sm font-normal focus-ring-none selection:bg-indigo-300 dark:selection:bg-indigo-700"
        @mousedown.stop
        @input="onInput"
        @paste="onPaste"
        @keydown="onKeydown"
        @blur="onBlur" />
    </div>
    <template v-if="!pageStore.isMobile">
      <div class="pointer-events-auto flex items-center gap-1.5">
        <TaskChips
          :task="task"
          :editor-mode="editorMode"
          :has-shown-parent="hasShownParent"
          :subtasks-expanded="subtasksExpanded"
          @open-task-in-detail="openTaskInDetail(false)"
          @jump-to-description="jumpToDescription"
          @jump-to-comments="jumpToComments"
          @toggle-subtasks="toggleSubtasks"
          @open-reminder="clickReminderOpen" />
      </div>
      <div
        v-if="!isListMiniTcmMode"
        class="dart-open-rightbar flex h-full grow items-center justify-end"
        :class="isListMiniNonRoadmapMode ? 'cursor-default' : 'cursor-pointer'"
        @click="openTaskInDetail(false)"
        @keydown.enter="openTaskInDetail(false)">
        <Tooltip
          :disabled="openTaskDisabled"
          :command-id="CommandId.OPEN_TASK_IN_RIGHTBAR"
          :hide-strokes="isListMiniNonRoadmapMode">
          <div
            class="flex h-[22px] min-w-[22px] cursor-pointer items-center rounded border border-gray-900/60 text-gray-900/60 hover:bg-gray-900/10 dark:border-white/50 dark:text-white/50 dark:hover:bg-white/10 print:hidden"
            :class="{
              'opacity-0': !isSelected || openTaskDisabled,
              'group-hover/title:opacity-100': !isSelected && !openTaskDisabled,
            }"
            data-testid="open-task-in-detail"
            @click="openTaskInDetail(true)"
            @keydown.enter="openTaskInDetail(true)">
            <component
              :is="userStore.taskDetailMode === TaskDetailMode.RIGHTBAR ? ChevronLeftDoubleIcon : FullscreenIcon"
              :class="userStore.taskDetailMode === TaskDetailMode.RIGHTBAR ? 'size-5' : 'm-0.5 size-4'" />
            <div v-if="isSelected && !openTaskDisabled" class="pr-1 text-xs">Open</div>
          </div>
        </Tooltip>
      </div>
    </template>
  </div>
</template>
