<script setup lang="ts">
import { computed, ref } from "vue";

import Button from "~/components/dumb/Button.vue";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import Tooltip from "~/components/dumb/Tooltip.vue";
import { CREATE_YOUR_WORKSPACE, ONBOARDING_TUTORIAL_NAMES_TO_DEFINITIONS } from "~/constants/tutorial";
import { CheckCircleIcon, DotsHorizontalIcon, HideIcon, TrashIcon } from "~/icons";
import { ButtonSize, ButtonStyle, DropdownMenuItemKind, IconSize, TutorialName, UserRole } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { firework } from "~/utils/fun";

const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const hidden = ref(false);

const steps = computed(() =>
  [
    {
      id: "create-your-workspace",
      ...CREATE_YOUR_WORKSPACE,
      completed: true,
    },
    ...[...ONBOARDING_TUTORIAL_NAMES_TO_DEFINITIONS].map(([name, tutorial]) => ({
      id: name,
      ...tutorial,
      completed: (userStore.getTutorialStatus(name) ?? 1) > 1,
    })),
  ].filter(
    (step) =>
      !(
        step.id === TutorialName.BRAINSTORM_WITH_AI &&
        (!pageStore.isOnline || !userStore.isRoleGreaterOrEqual(UserRole.ADMIN))
      ) && !(step.id === TutorialName.INVITE_TEAMMATES && !userStore.isRoleGreaterOrEqual(UserRole.MEMBER))
  )
);
const finishedAllSteps = computed(() => steps.value.every((step) => step.completed));

const completeTutorial = (celebrate = true) => {
  userStore.updateTutorialStatuses([{ name: TutorialName.CHECKLIST_COMPLETED, status: 2 }]);

  if (!celebrate) {
    return;
  }

  firework();
};

const defaultUpdates = () => {
  const updates = [];
  if (pageStore.isNativeApp) {
    updates.push({ name: TutorialName.DOWNLOAD_THE_APPS, status: 2 });
  }
  if (userStore.imageUrl) {
    updates.push({ name: TutorialName.SET_A_PROFILE_PICTURE, status: 2 });
  }
  userStore.updateTutorialStatuses(updates);
};
defaultUpdates();

const dropdownSections = computed(() => [
  {
    title: "Options",
    items: [
      {
        title: "Hide for now",
        kind: DropdownMenuItemKind.BUTTON,
        icon: HideIcon,
        onClick: () => {
          hidden.value = true;
        },
      },
      {
        title: "Close forever",
        kind: DropdownMenuItemKind.BUTTON,
        icon: TrashIcon,
        onClick: () => {
          completeTutorial(false);
        },
      },
    ],
  },
]);

const onContextMenu = (event: MouseEvent) => {
  if (tenantStore.isDart && !pageStore.adminHidden && event.altKey) {
    return;
  }

  appStore.openContextMenu(event as PointerEvent, dropdownSections.value);
};
</script>

<template>
  <div
    v-if="!hidden && !appStore.onboardingChecklistCompleted"
    data-testid="onboarding-checklist"
    class="flex flex-col gap-2 overflow-hidden rounded border border-primary-base/40 bg-primary-base/20 py-2.5"
    @contextmenu="onContextMenu">
    <!-- Heading -->
    <div class="flex items-center justify-between pl-[15px] pr-2.5">
      <div class="select-none text-sm font-semibold text-hvy">Get started!</div>
      <DropdownMenu v-if="!finishedAllSteps" :sections="dropdownSections" :width-pixels="180">
        <Tooltip text="More options">
          <Button
            :btn-style="ButtonStyle.SECONDARY"
            :icon="DotsHorizontalIcon"
            :icon-size="IconSize.S"
            borderless
            class="hover:!bg-primary-base/20"
            a11y-label="Options" />
        </Tooltip>
      </DropdownMenu>
    </div>

    <!-- Items -->
    <div class="flex flex-col gap-0.5 px-1.5">
      <button
        v-for="step in steps"
        :key="step.id"
        type="button"
        class="flex items-center gap-2 rounded px-[9px] py-1"
        :class="{
          'cursor-default': step.completed,
          'hover:bg-primary-base/20': !step.completed,
        }"
        @click="() => !step.completed && step.onClick?.(dataStore)">
        <component
          :is="step.completed ? CheckCircleIcon : step.icon"
          class="flex icon-sm"
          :class="step.completed ? 'text-success-base' : 'text-primary-base'" />
        <span class="select-none text-left text-sm" :class="step.completed ? 'line-through text-lt' : 'text-hvy'">
          {{ step.title }}
        </span>
      </button>
    </div>

    <Button
      v-if="finishedAllSteps"
      :btn-style="ButtonStyle.PRIMARY"
      :size="ButtonSize.SMALL"
      :icon-size="IconSize.XS"
      text="Finish"
      class="mx-2.5 self-end !px-2.5 !py-0.5"
      @click="completeTutorial()" />
  </div>
</template>
