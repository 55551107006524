<script setup lang="ts">
import { ClaudeIcon, OpenExternalLinkIcon } from "~/icons";
</script>

<template>
  <div class="flex flex-col gap-y-16">
    <div class="flex flex-col justify-center space-y-3">
      <div class="flex justify-between">
        <div class="flex gap-x-4">
          <h2 class="flex select-none items-center text-xl text-md">Claude integration</h2>
        </div>
      </div>
      <div class="select-none text-sm/relaxed text-lt">
        <p>
          Integrate Claude with Dart to look through, create, and update tasks and docs without having to leave Claude.
        </p>
        <p>
          The integration runs via a Model Context Protocol (MCP) server, which you can set up using the link below.
        </p>
      </div>
    </div>

    <a
      href="https://github.com/its-dart/dart-mcp-server"
      target="_blank"
      rel="noopener noreferrer"
      class="flex select-none items-center justify-center gap-2 self-center rounded border px-3 py-2 text-center shadow-sm text-md border-md focus-ring-std hover:bg-lt">
      <ClaudeIcon class="icon-md" />
      Link Dart and Claude
      <OpenExternalLinkIcon class="icon-md" />
    </a>
  </div>
</template>
