<script setup lang="ts">
import { computed } from "vue";
import { useRouter } from "vue-router";

import actions from "~/actions";
import Button from "~/components/dumb/Button.vue";
import TutorialMessage from "~/components/dumb/TutorialMessage.vue";
import { AccountSettingsIcon, HomeIcon, InboxIcon, MyTasksIcon, PlusIcon, SearchIcon } from "~/icons";
import { makeLinkToSettingsRef } from "~/router/common";
import { ButtonStyle, IconSize, PageKind, Placement, TutorialName, ViewKind } from "~/shared/enums";
import { useAppStore, useDataStore, usePageStore } from "~/stores";
import { getViewLink } from "~/utils/common";

const BUTTON_STYLE = "flex-col grow text-xs py-1 !px-1 rounded-none";

const router = useRouter();
const appStore = useAppStore();
const dataStore = useDataStore();
const pageStore = usePageStore();

const pageKind = computed(() => appStore.currentPage?.pageKind);

// TODO simplify duplicated code from leftbar header
const actionNewText = computed(() =>
  pageKind.value === PageKind.SPACE
    ? "New report"
    : pageKind.value === PageKind.FOLDER
      ? "New doc"
      : pageKind.value === PageKind.DASHBOARDS_ROOT
        ? "New dashboard"
        : dataStore.taskDraft
          ? "Resume draft"
          : "New task"
);

const contextualCreate = () => actions.visualization.contextualCreate();

const isSearchModalOpen = computed(() => appStore.searchModalOpen);
const isSettingsModalOpen = computed(() => appStore.settingsModalOpen);
const isModalOpen = computed(() => isSearchModalOpen.value || isSettingsModalOpen.value);
const onHomePage = computed(() => router.currentRoute.value.name === "home" && !isModalOpen.value);
const onMyTasksPage = computed(() => appStore.currentPage?.kind === ViewKind.MY_TASKS && !isModalOpen.value);
const onInboxPage = computed(() => router.currentRoute.value.name === "inbox" && !isModalOpen.value);

const openHomePage = () => router.push({ name: "home" });
const openMyTasksPage = () => router.push(getViewLink(dataStore.getViewByKind(ViewKind.MY_TASKS)));
const openInboxPage = () => router.push({ name: "inbox" });
const openSearch = () => appStore.setSearchModalOpen(true);
const openAccountSettings = () => router.push(makeLinkToSettingsRef("account").value);
</script>

<template>
  <div class="absolute bottom-[88px] right-3">
    <TutorialMessage
      :name="TutorialName.CREATE_TASK_WITH_SUBTASK_RECS"
      :step="1"
      :disabled="pageKind === PageKind.SPACE || pageKind === PageKind.FOLDER || !!dataStore.taskDraft"
      complete-on-click
      :placement="Placement.TOP_RIGHT"
      block
      shadow-styles="rounded-full">
      <div class="flex items-center">
        <Button
          :text="actionNewText"
          :icon="PlusIcon"
          :btn-style="ButtonStyle.PRIMARY"
          class="rounded-full pl-3"
          data-testid="mobile-new-button"
          @click="contextualCreate" />
      </div>
    </TutorialMessage>
  </div>
  <div
    data-testid="mobile-bottom-bar"
    class="flex w-full items-center justify-center border-t px-1 pt-1 bg-std border-md"
    :class="pageStore.isIos && pageStore.isMobileApp ? 'pb-[22x]' : 'pb-1'">
    <Button
      text="Home"
      :icon="HomeIcon"
      :icon-size="IconSize.M"
      :btn-style="ButtonStyle.SECONDARY"
      borderless
      class="w-1/5 !rounded-md"
      :class="[BUTTON_STYLE, onHomePage ? 'bg-lt text-hvy' : 'text-vlt']"
      :text-style="onHomePage ? 'text-hvy' : 'text-lt'"
      @click="openHomePage" />
    <Button
      text="My tasks"
      :icon="MyTasksIcon"
      :icon-size="IconSize.M"
      :btn-style="ButtonStyle.SECONDARY"
      borderless
      class="w-1/5 !rounded-md"
      :class="[BUTTON_STYLE, onMyTasksPage ? 'bg-lt text-hvy' : 'text-vlt']"
      :text-style="onMyTasksPage ? 'text-hvy' : 'text-lt'"
      @click="openMyTasksPage" />
    <div class="relative w-1/5">
      <Button
        text="Inbox"
        :icon="InboxIcon"
        :icon-size="IconSize.M"
        :btn-style="ButtonStyle.SECONDARY"
        borderless
        class="w-full !rounded-md"
        :class="[BUTTON_STYLE, onInboxPage ? 'bg-lt text-hvy' : 'text-vlt']"
        :text-style="onInboxPage ? 'text-hvy' : 'text-lt'"
        @click="openInboxPage" />
      <!-- TODO componentize to avoid duplication -->
      <div
        v-if="dataStore.unreadNotificationCount > 0"
        class="absolute right-2 top-1 flex min-h-4 min-w-4 items-center justify-center rounded bg-primary-base px-[3px] text-[10px]/[13px] font-semibold text-oncolor"
        @click="openInboxPage"
        @keydown.enter="openInboxPage">
        {{ dataStore.unreadNotificationCount }}
      </div>
    </div>
    <Button
      text="Search"
      :icon="SearchIcon"
      :icon-size="IconSize.M"
      :btn-style="ButtonStyle.SECONDARY"
      borderless
      class="w-1/5 !rounded-md"
      :class="[BUTTON_STYLE, isSearchModalOpen ? 'bg-lt text-hvy' : 'text-vlt']"
      :text-style="isSearchModalOpen ? 'text-hvy' : 'text-lt'"
      @click="openSearch" />
    <Button
      text="Account"
      :icon="AccountSettingsIcon"
      :icon-size="IconSize.M"
      :btn-style="ButtonStyle.SECONDARY"
      borderless
      class="w-1/5 !rounded-md"
      :class="[BUTTON_STYLE, isSettingsModalOpen ? 'bg-lt text-hvy' : 'text-vlt']"
      :text-style="isSettingsModalOpen ? 'text-hvy' : 'text-lt'"
      @click="openAccountSettings" />
  </div>
</template>
