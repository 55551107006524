<script setup lang="ts" generic="T">
import {
  Dialog,
  DialogDescription,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { type Component, computed, nextTick, type Ref, ref, watch } from "vue";

import Tooltip from "~/components/dumb/Tooltip.vue";
import MobileNavigationSwipe from "~/components/MobileNavigationSwipe.vue";
import MobileRefreshSwipe from "~/components/MobileRefreshSwipe.vue";
import { colorsByTheme } from "~/constants/style";
import { XIcon } from "~/icons";
import { IconMode, ModalPosition, ModalWidth } from "~/shared/enums";
import { usePageStore } from "~/stores";

const TRANSITION_RIGHT = {
  enter: "ease-in-out duration-150",
  enterFrom: "translate-x-full",
  enterTo: "translate-x-0",
  leave: "ease-in-out duration-150",
  leaveFrom: "translate-x-0",
  leaveTo: "translate-x-full",
};
const TRANSITION_LEFT = {
  enter: "ease-in-out duration-150",
  enterFrom: "-translate-x-full",
  enterTo: "translate-x-0",
  leave: "ease-in-out duration-150",
  leaveFrom: "translate-x-0",
  leaveTo: "-translate-x-full",
};
const TRANSITION_NORMAL = {
  enter: "duration-150 ease-out",
  enterFrom: "opacity-0 scale-95",
  enterTo: "opacity-100 scale-100",
  leave: "duration-150 ease-in",
  leaveFrom: "opacity-100 scale-100",
  leaveTo: "opacity-0 scale-95",
};

const WIDTH_MAP: Record<ModalWidth, number | undefined> = {
  [ModalWidth.XS]: 238,
  [ModalWidth.S]: 400,
  [ModalWidth.M]: 512,
  [ModalWidth.L]: 672,
  [ModalWidth.XL]: 800,
  [ModalWidth.XXL]: 1152,
  [ModalWidth.XXXL]: 1600,
  [ModalWidth.FULL]: undefined,
  [ModalWidth.CUSTOM]: undefined,
};

const props = defineProps<{
  entity: T;
  title: string | ((t: T) => string);
  id?: string;
  hideTitle?: boolean;
  emphasizeTitle?: boolean;
  description?: string | ((t: T) => string);
  width: ModalWidth;
  position?: ModalPosition;
  iconMode?: IconMode;
  closeText?: string;
  customStyles?: string;
  customWidthPixels?: number;
  closeIconStyles?: string;
  titleIcon?: Component;
  titleIconArgs?: object | ((t: T) => object);
  hFull?: boolean;
  backdropBlur?: boolean;
  overflowClip?: boolean;
  selected?: boolean;
  noFocus?: boolean;
  dataTestid?: string;
}>();

const emit = defineEmits<{
  close: [];
  keydown: [event: KeyboardEvent];
}>();

const pageStore = usePageStore();

const opening = ref(!!props.entity);
watch(
  () => props.entity,
  (newEntity) => {
    if (!newEntity) {
      return;
    }
    opening.value = true;
    // eslint-disable-next-line no-restricted-syntax
    setTimeout(() => {
      opening.value = false;
    });
  },
  { immediate: true }
);

const colors = computed(() => colorsByTheme[pageStore.theme]);
const positionLeft = computed(() => props.position === ModalPosition.LEFT);
const positionRight = computed(() => props.position === ModalPosition.RIGHT);
const positionTop = computed(() => props.position === ModalPosition.TOP);
const positionBottom = computed(() => props.position === ModalPosition.BOTTOM);
const iconMode = computed(() => props.iconMode ?? IconMode.NONE);

const displayedEntity = ref(props.entity) as Ref<T>;
const widthStyle = computed(() => {
  const width = props.customWidthPixels ?? WIDTH_MAP[props.width];
  return width ? { maxWidth: `${width}px` } : {};
});

const realizeStr = (unrealized?: string | ((t: T) => string)) => {
  if (typeof unrealized === "function") {
    return unrealized(displayedEntity.value);
  }
  return unrealized;
};

const realizedTitleIconArgs = computed(() => {
  if (props.titleIconArgs === undefined) {
    return {};
  }
  if (typeof props.titleIconArgs === "function") {
    return props.titleIconArgs(displayedEntity.value);
  }
  return props.titleIconArgs;
});

const displayedTitle = ref(realizeStr(props.title));
const displayedDescription = ref(realizeStr(props.description));
const normCloseText = computed(() => props.closeText ?? "Close");

const focusRef = ref<HTMLDivElement | null>(null);

watch(
  () => props.entity,
  (newEntity) => {
    if (!props.entity) {
      return;
    }
    if (props.noFocus) {
      nextTick(() => focusRef.value?.focus());
    }
    displayedEntity.value = newEntity;
  }
);

watch([() => props.title, () => displayedEntity], ([newValue]) => {
  displayedTitle.value = realizeStr(newValue);
});

watch(
  () => props.description,
  (newDescription) => {
    displayedDescription.value = realizeStr(newDescription);
  }
);
</script>

<template>
  <TransitionRoot appear :show="!!entity" as="template">
    <Dialog as="div" class="relative z-10 h-full" @close="emit('close')">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <DialogOverlay
          class="fixed inset-0 bg-gray-600/40 dark:bg-zinc-500/40"
          :class="backdropBlur && 'backdrop-blur-[3px]'" />
      </TransitionChild>

      <div
        class="dart-modal-wrapper fixed inset-0"
        :class="!overflowClip && !positionRight && 'overflow-y-auto'"
        :style="{ '--background': colors.bgStd, '--highlight': colors.highlight }"
        @keydown="emit('keydown', $event)">
        <MobileRefreshSwipe v-if="pageStore.isMobile" />

        <div
          class="flex h-full flex-col items-center px-1 pb-1"
          :class="[
            positionBottom
              ? 'justify-end pb-2.5'
              : positionTop
                ? 'justify-start pt-2.5 sm:px-3 sm:pb-3'
                : 'justify-evenly pt-10 sm:p-10',
          ]">
          <TransitionChild
            as="template"
            v-bind="positionLeft ? TRANSITION_LEFT : positionRight ? TRANSITION_RIGHT : TRANSITION_NORMAL">
            <DialogPanel
              :id="id"
              class="w-full shadow-xl transition-all"
              :style="widthStyle"
              :data-testid="dataTestid"
              :class="{
                'absolute inset-y-2': positionLeft || positionRight,
                'left-2': positionLeft,
                'right-2': positionRight,
                relative: !positionLeft && !positionRight,
                'h-full': hFull,
                'select-none': !selected,
              }">
              <div v-if="noFocus" ref="focusRef" tabindex="-1" class="sr-only" />
              <div
                v-if="!opening"
                class="flex w-full flex-col rounded-lg px-6 pb-6 pt-4 text-left align-middle app-drag-none bg-std"
                :class="customStyles"
                :style="widthStyle">
                <XIcon class="sr-only" />

                <!-- Header -->
                <div class="mb-1 mr-3 flex flex-col gap-1" :class="hideTitle && 'sr-only'">
                  <div class="flex items-center gap-3">
                    <div
                      v-if="titleIcon"
                      :class="{
                        'mr-1 mt-2 flex size-10 shrink-0 items-center justify-center rounded-full':
                          iconMode !== IconMode.NONE,
                        'bg-danger-base/30 text-danger-base': iconMode === IconMode.DELETE,
                        'bg-primary-base/30 text-primary-base': iconMode === IconMode.PRIMARY,
                        'contents text-lt': iconMode === IconMode.NONE,
                      }">
                      <component
                        :is="titleIcon"
                        v-bind="realizedTitleIconArgs"
                        class="icon-md"
                        data-testid="modal-icon" />
                    </div>
                    <DialogTitle
                      as="h3"
                      class="select-none hyphens-auto text-md break-words"
                      :class="emphasizeTitle ? 'text-3xl font-bold' : 'text-lg font-semibold'">
                      {{ displayedTitle }}
                    </DialogTitle>
                  </div>
                  <DialogDescription
                    v-if="displayedDescription"
                    class="select-none text-sm text-lt"
                    :class="iconMode !== IconMode.NONE && titleIcon && 'ml-[56px]'">
                    {{ displayedDescription }}
                  </DialogDescription>
                </div>

                <!-- Content -->
                <slot :entity="displayedEntity" />

                <!-- Footer -->
                <div v-if="$slots.footerLeft || $slots.actions" class="mt-3 flex justify-between">
                  <slot v-if="$slots.footerLeft" name="footerLeft" :entity="displayedEntity" />
                  <div v-else />

                  <slot v-if="$slots.actions" name="actions" :entity="displayedEntity" />
                  <div v-else />
                </div>

                <!-- x in top right -->
                <div
                  v-if="!pageStore.isMobile"
                  class="absolute top-3 flex"
                  :class="positionLeft ? 'left-[calc(100%+12px)]' : 'right-3'">
                  <Tooltip :text="normCloseText">
                    <div
                      class="flex cursor-pointer items-center rounded p-0.5 focus-ring-std"
                      :class="[
                        positionLeft
                          ? 'text-white hover:bg-gray-400/80 dark:hover:bg-zinc-500/80'
                          : 'text-vlt hover:bg-md',
                        closeIconStyles,
                      ]"
                      data-testid="close-modal"
                      @click="emit('close')"
                      @keydown.enter="emit('close')">
                      <XIcon class="icon-sm" />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <MobileNavigationSwipe v-if="pageStore.isMobile" />
            </DialogPanel>
          </TransitionChild>
          <div />
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
.dart-modal-wrapper::-webkit-scrollbar {
  background: var(--background);
}
</style>
