<script setup lang="ts">
import { type Component, computed } from "vue";
import { useRouter } from "vue-router";

import { getPropertyConfig } from "~/common/properties";
import DropdownMenu from "~/components/dumb/DropdownMenu.vue";
import { REVIEWER_TENANTS } from "~/constants/app";
import { colorsByTheme } from "~/constants/style";
import {
  AccountSettingsIcon,
  AgentIcon,
  ApiIcon,
  AutomationsIcon,
  ChatgptIcon,
  ClaudeIcon,
  DiscordIcon,
  ExportIcon,
  FormIcon,
  GithubIcon,
  IdentityIcon,
  ImportIcon,
  MailIcon,
  MenuIcon,
  NotificationIcon,
  NotionIcon,
  PipedreamIcon,
  PlansAndBillingSettingsIcon,
  PlusIcon,
  PremiumFeatureIcon,
  PropertiesIcon,
  SlackIcon,
  TaskTypeFieldIcon,
  UsersInviteIcon,
  WebhooksIcon,
  WorkspaceSettingsIcon,
  ZapierIcon,
  ZohoFlowIcon,
} from "~/icons";
import { makeLinkToSettingsRef, makeLinkToSpaceSettingsPageRef } from "~/router/common";
import { DropdownMenuItemKind, Entitlement, SpaceKind, UserRole } from "~/shared/enums";
import type { Page, Space } from "~/shared/types";
import { useDataStore, usePageStore, useTenantStore, useUserStore } from "~/stores";
import { getOrdersBetween } from "~/utils/orderManager";
import SpacesList from "~/views/settings/SpacesView/SpacesList.vue";

import Tooltip from "../dumb/Tooltip.vue";

type Tab = {
  title: string;
  icon?: Component;
  settingsPage: string;
  upgradeRequired?: boolean;
  hidden?: boolean;
  minRole?: UserRole;
  overrideRoles?: UserRole[];
  page?: Page;
};

const router = useRouter();
const dataStore = useDataStore();
const pageStore = usePageStore();
const tenantStore = useTenantStore();
const userStore = useUserStore();

const colors = computed(() => colorsByTheme[pageStore.theme]);

const upgradeRequiredForUserLimit = computed(() => {
  const userLimit = tenantStore.getEntitlementValue(Entitlement.MAX_USERS);
  return dataStore.getUserList({ includeSpecialists: true }).length >= userLimit;
});

const isReviewer = computed(() => REVIEWER_TENANTS.has(tenantStore.duid));

const annotateTabsWithIdAndActive = (tabs: Tab[]) =>
  tabs
    .filter(
      (e) =>
        !e.hidden &&
        (e.minRole === undefined ||
          userStore.isRoleGreaterOrEqual(e.minRole) ||
          e.overrideRoles?.includes(userStore.role))
    )
    .map((tab, i) => ({
      ...tab,
      id: i,
      active: tab.settingsPage === router.currentRoute.value.query.settings,
      link: makeLinkToSettingsRef(tab.settingsPage, { property: undefined }).value,
    }));

const accountNav = computed(() =>
  annotateTabsWithIdAndActive([
    {
      title: "Account",
      icon: AccountSettingsIcon,
      settingsPage: "account",
    },
    {
      title: "Notifications",
      icon: NotificationIcon,
      settingsPage: "notifications",
    },
  ])
);

const workspaceNav = computed(() =>
  annotateTabsWithIdAndActive([
    {
      title: "Preferences",
      icon: WorkspaceSettingsIcon,
      settingsPage: "workspace",
      minRole: UserRole.ADMIN,
    },
    {
      title: "Teammates",
      icon: UsersInviteIcon,
      settingsPage: "teammates",
      upgradeRequired: upgradeRequiredForUserLimit.value,
      minRole: UserRole.MEMBER,
    },
    {
      title: "Identity",
      icon: IdentityIcon,
      settingsPage: "identity",
      upgradeRequired: !tenantStore.isPremium,
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Types",
      icon: TaskTypeFieldIcon,
      settingsPage: "types",
      upgradeRequired: dataStore.taskKindList.length >= tenantStore.getEntitlementValue(Entitlement.MAX_TASK_KINDS),
      minRole: UserRole.ADMIN,
    },
    {
      title: "Properties",
      icon: PropertiesIcon,
      settingsPage: "properties",
      upgradeRequired:
        dataStore.propertyList.filter((e) => !getPropertyConfig(e.kind).isDefault).length >=
        tenantStore.getEntitlementValue(Entitlement.MAX_CUSTOM_PROPERTIES),
      minRole: UserRole.ADMIN,
    },
    {
      title: "Forms",
      icon: FormIcon,
      settingsPage: "forms",
      upgradeRequired: dataStore.formList.length >= tenantStore.getEntitlementValue(Entitlement.MAX_FORMS),
      minRole: UserRole.MEMBER,
    },
    {
      title: "Plans and billing",
      hidden: isReviewer.value,
      icon: PlansAndBillingSettingsIcon,
      settingsPage: "billing",
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.FINANCIAL_ADMIN],
    },
    {
      title: "Import",
      icon: ImportIcon,
      settingsPage: "import",
      minRole: UserRole.MEMBER,
    },
    {
      title: "Export",
      icon: ExportIcon,
      settingsPage: "export",
      minRole: UserRole.GUEST,
    },
  ])
);

const aiNav = computed(() =>
  annotateTabsWithIdAndActive([
    {
      title: "ChatGPT",
      hidden: isReviewer.value,
      icon: ChatgptIcon,
      settingsPage: "chatgpt",
      minRole: UserRole.GUEST,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Claude",
      hidden: isReviewer.value,
      icon: ClaudeIcon,
      settingsPage: "claude",
      minRole: UserRole.GUEST,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Agents",
      icon: AgentIcon,
      settingsPage: "agents",
      upgradeRequired: upgradeRequiredForUserLimit.value,
      minRole: UserRole.MEMBER,
      hidden: !tenantStore.isDart,
    },
    {
      title: "API",
      icon: ApiIcon,
      settingsPage: "cli",
      minRole: UserRole.GUEST,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Webhooks",
      icon: WebhooksIcon,
      settingsPage: "webhooks",
      upgradeRequired: !tenantStore.getEntitlementValue(Entitlement.WEBHOOKS),
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Automations",
      icon: AutomationsIcon,
      settingsPage: "automations",
      minRole: UserRole.ADMIN,
    },
  ])
);

const spacesNav = computed(() =>
  dataStore.spaceList.map((space: Space) => ({
    title: space.title,
    settingsPage: "spaces",
    page: space,
    id: space.duid,
    active: space.duid === router.currentRoute.value.query.space,
    link: makeLinkToSpaceSettingsPageRef(space.duid, "basics").value,
  }))
);

const integrationsNav = computed(() =>
  annotateTabsWithIdAndActive([
    {
      title: "Notion",
      icon: NotionIcon,
      settingsPage: "notion",
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Slack",
      icon: SlackIcon,
      settingsPage: "slack",
      upgradeRequired: !tenantStore.getEntitlementValue(Entitlement.SLACK),
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Discord",
      icon: DiscordIcon,
      settingsPage: "discord",
      upgradeRequired: !tenantStore.getEntitlementValue(Entitlement.DISCORD),
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "GitHub",
      icon: GithubIcon,
      settingsPage: "github",
      upgradeRequired: !tenantStore.getEntitlementValue(Entitlement.GITHUB),
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Zapier",
      icon: ZapierIcon,
      settingsPage: "zapier",
      upgradeRequired: !tenantStore.getEntitlementValue(Entitlement.ZAPIER),
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Zoho Flow",
      icon: ZohoFlowIcon,
      settingsPage: "zoho-flow",
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Pipedream",
      icon: PipedreamIcon,
      settingsPage: "pipedream",
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
    {
      title: "Email",
      icon: MailIcon,
      settingsPage: "mail",
      minRole: UserRole.ADMIN,
      overrideRoles: [UserRole.TECHNICAL_ADMIN],
    },
  ])
);

const customSpaces = computed(() => dataStore.spaceList.filter((e) => e.kind === SpaceKind.OTHER));
const createSpace = async () => {
  const topSpaceOrder = customSpaces.value.length === 0 ? undefined : customSpaces.value[0].order;
  const order = getOrdersBetween(undefined, topSpaceOrder)[0];

  const newSpace = await dataStore.createSpace(order, { order });

  router.replace(makeLinkToSpaceSettingsPageRef(newSpace.duid, "basics").value);
};

const createTab = (tab: Tab) => ({
  title: tab.title,
  icon: tab.icon,
  selected: tab.settingsPage === router.currentRoute.value.query.settings,
  kind: DropdownMenuItemKind.INTERNAL_LINK,
  navigate: { to: makeLinkToSettingsRef(tab.settingsPage).value },
});

const mobileDropdownSections = computed(() => [
  {
    title: "Account",
    showTitle: true,
    items: accountNav.value.map(createTab),
  },
  {
    title: "Workspace",
    showTitle: true,
    items: workspaceNav.value.map(createTab),
  },
  {
    title: "AI",
    showTitle: true,
    items: aiNav.value.map(createTab),
  },
  {
    title: "Spaces",
    showTitle: true,
    items: [
      {
        title: "Spaces",
        kind: DropdownMenuItemKind.COMPONENT,
        hidden: !userStore.isRoleGreaterOrEqual(UserRole.MEMBER),
        component: SpacesList,
      },
      {
        title: "New Space",
        kind: DropdownMenuItemKind.BUTTON,
        icon: PlusIcon,
        hidden: !userStore.isRoleGreaterOrEqual(UserRole.MEMBER),
        onClick: createSpace,
      },
    ],
  },
  {
    title: "Integrations",
    showTitle: true,
    items: integrationsNav.value.map(createTab),
  },
]);
</script>

<template>
  <nav class="z-10">
    <div class="sticky top-0 flex h-14 shrink-0 border-b bg-std border-md sm:hidden">
      <DropdownMenu :sections="mobileDropdownSections" class="m-4">
        <div class="flex hover:bg-lt">
          <span class="sr-only">Settings pages</span>
          <MenuIcon class="text-vlt icon-lg" />
        </div>
      </DropdownMenu>
    </div>
    <div class="hidden sm:block sm:h-full">
      <div
        class="h-full w-56 flex-none space-y-5 overflow-x-auto px-2.5 py-4 bg-lt"
        :style="{ '--background': colors.borderVlt, '--highlight': colors.borderMd }">
        <!-- TODO componentize or loop through the sections -->
        <!-- Account -->
        <div v-if="accountNav.length > 0">
          <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">Personal</span>
          <div class="mt-1 space-y-px">
            <div v-for="item in accountNav" :key="item.id">
              <RouterLink
                :to="item.link"
                :class="item.active ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
                class="flex select-none items-center rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt">
                <component
                  :is="item.icon"
                  :class="[item.active ? 'text-primary-base' : 'text-lt', 'icon-sm']"
                  class="mr-1.5" />
                {{ item.title }}
              </RouterLink>
            </div>
          </div>
        </div>

        <!-- Workspace -->
        <div v-if="workspaceNav.length > 0">
          <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">Workspace</span>
          <div class="mt-1 space-y-px">
            <div v-for="item in workspaceNav" :key="item.id">
              <RouterLink
                :to="item.link"
                :class="item.active ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
                class="flex select-none items-center rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt">
                <component
                  :is="item.icon"
                  :class="[item.active ? 'text-primary-base' : 'text-lt', 'icon-sm']"
                  class="mr-1.5" />
                {{ item.title }}
                <Tooltip v-if="item.upgradeRequired" text="Premium feature" class="ml-2.5">
                  <PremiumFeatureIcon class="text-vlt focus-ring-none icon-sm" />
                </Tooltip>
              </RouterLink>
            </div>
          </div>
        </div>

        <!-- AI -->
        <div v-if="aiNav.length > 0">
          <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">AI</span>
          <div class="mt-1 space-y-px">
            <div v-for="item in aiNav" :key="item.id">
              <RouterLink
                :to="item.link"
                :class="item.active ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
                class="flex select-none items-center rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt">
                <component
                  :is="item.icon"
                  :class="[item.active ? 'text-primary-base' : 'text-lt', 'icon-sm']"
                  class="mr-1.5" />
                {{ item.title }}
                <Tooltip v-if="item.upgradeRequired" text="Premium feature" class="ml-2.5">
                  <PremiumFeatureIcon class="text-vlt focus-ring-none icon-sm" />
                </Tooltip>
              </RouterLink>
            </div>
          </div>
        </div>

        <!-- Spaces -->
        <div v-if="spacesNav.length > 0 && userStore.isRoleGreaterOrEqual(UserRole.MEMBER)" data-testid="space-list">
          <SpacesList />
        </div>

        <!-- Integrations -->
        <div v-if="integrationsNav.length > 0">
          <span class="select-none px-2.5 text-xs font-semibold uppercase text-vlt">Integrations</span>
          <div class="mt-1 space-y-px">
            <div v-for="item in integrationsNav" :key="item.id">
              <RouterLink
                :to="item.link"
                :class="item.active ? 'bg-md hover:bg-hvy' : 'hover:bg-md'"
                class="flex select-none items-center rounded px-2.5 py-1 text-sm font-medium text-lt focus-ring-lt">
                <component
                  :is="item.icon"
                  :class="[item.active ? 'text-primary-base' : 'text-lt', 'icon-sm']"
                  class="mr-1.5" />
                {{ item.title }}
                <Tooltip v-if="item.upgradeRequired" text="Premium feature" class="ml-1">
                  <PremiumFeatureIcon class="text-vlt focus-ring-none icon-sm" />
                </Tooltip>
              </RouterLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
